<template>
  <ContentWrapper :title="labels.LBL_TEAM_MEMBERS">
    <template slot="actions">
      <el-button size="small" type="success" class="ml-3" @click="handleAdd">
        <i class="el-icon-plus mr-1"></i>
        {{ labels.LBL_ADD }}
      </el-button>
    </template>

    <el-table
      v-loading="loading"
      :data="executiveList"
      :default-sort="{ prop: 'created_at', order: 'descending' }"
      :empty-text="labels.LBL_NO_DATA"
    >
      <el-table-column :label="labels.LBL_PHOTO" width="80">
        <template slot-scope="scope">
          <el-avatar
            :src="scope.row.photo_url"
            :size="40"
            shape="circle"
            fit="contain"
          >
            <img
              src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"
            />
          </el-avatar>
        </template>
      </el-table-column>

      <el-table-column :label="labels.LBL_NAME">
        <template slot-scope="scope">
          {{ `${scope.row.first_name} ${scope.row.last_name}` }}
          <span v-if="loggedInUserId && scope.row.id === loggedInUserId">
            (You)
          </span>
        </template>
      </el-table-column>

      <el-table-column prop="designation" :label="labels.LBL_DESIGNATION">
      </el-table-column>

      <el-table-column :label="labels.LBL_LOGIN_ACCESS">
        <template slot-scope="scope">
          <el-button
            size="mini"
            :type="!scope.row.can_login ? 'success' : 'danger'"
            v-text="!scope.row.can_login ? labels.LBL_GRANT : labels.LBL_REVOKE"
            :disabled="loggedInUserId && scope.row.id === loggedInUserId"
            @click="toggleAccess(scope.row)"
          >
          </el-button>
        </template>
      </el-table-column>

      <el-table-column align="right" width="300">
        <template slot="header" slot-scope="scope">
          <el-input
            v-model="search"
            size="mini"
            :placeholder="labels.LBL_TYPE_2_SEARCH"
            class="ml-1"
          />
        </template>

        <template slot-scope="scope">
          <el-button
            size="mini"
            type="info"
            icon="el-icon-edit"
            circle
            @click="handleEdit(scope.row)"
          >
          </el-button>

          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            circle
            :disabled="scope.row.id === loggedInUserId"
            @click="handleDelete(scope.row)"
          >
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </ContentWrapper>
</template>

<script>
  import _ from "lodash";
  import { mapState } from "vuex";
  import { labels } from "@/common";
  import ContentWrapper from "@/components/layouts/AdminContentWrapper";

  export default {
    name: "TeamMembers",

    components: {
      ContentWrapper,
    },

    data() {
      return {
        labels,

        search: "",
        params: {
          limit: 100,
        },
      };
    },

    computed: {
      ...mapState({
        loading: (state) => state.isBusy,
        executives: (state) => state.executive.executives,
        loggedInUser: (state) => state.auth.loggedInUser,
      }),

      loggedInUserId() {
        return this.loggedInUser.executive && this.loggedInUser.executive.id;
      },

      executiveList() {
        return this.executives && this.executives.data;
      },

      executivesDoesnotExists() {
        return this.executiveList && this.executiveList.length === 0;
      },
    },

    watch: {
      search: _.debounce(function(nv) {
        this.handleSearch(nv);
      }, 500),
    },

    mounted() {
      this.loadExecutives();
    },

    methods: {
      loadExecutives() {
        this.$store.dispatch("executive/getAllExecutives", this.params);
      },

      handleSearch(search) {
        this.$store.dispatch("executive/getAllExecutives", {
          ...this.params,
          search,
        });
      },

      handleAdd() {
        this.$router.push({ name: "Add Member" });
      },

      handleEdit({ id }) {
        this.$router.push({
          name: "Edit Member",
          params: { memberId: id },
        });
      },

      handleDelete({ id }) {
        this.$confirm(`${labels.CONF_DELETE}?`, labels.LBL_WARN, {
          confirmButtonText: labels.LBL_YES,
          cancelButtonText: labels.LBL_CANCEL,
          type: labels.LBL_WARN.toLowerCase(),
        })
          .then(() => {
            this.$store.dispatch("executive/deleteExecutive", id);
          })
          .catch(() => {
            this.$message({
              type: labels.LBL_INFO.toLowerCase(),
              message: labels.CNCL_DELETE,
            });
          });
      },

      toggleAccess(executive) {
        this.$confirm(
          `${labels.CONF_UPDATE} login access to <b>${executive.first_name}</b>`,
          labels.LBL_WARN,
          {
            dangerouslyUseHTMLString: true,
            confirmButtonText: labels.LBL_YES,
            cancelButtonText: labels.LBL_CANCEL,
            type: labels.LBL_WARN.toLowerCase(),
          }
        )
          .then(() => {
            this.$store.dispatch("executive/toggleAccess", executive.id);
          })
          .catch(() => {
            this.$message({
              type: labels.LBL_INFO.toLowerCase(),
              message: labels.CNCL_ACCESS,
            });
          });
      },
    },
  };
</script>

<style></style>
